<template>
    <div>
        <!-- 头部导航栏 -->
        <top-bar/>
        <!--内容页-->
        <div class="body">
            <!--播放器主体-->
            <div class="content">
                <el-row>
                    <el-col :span="18">
                        <!--视频播放器-->
                        <div>
                            <!--如果是回放，使用视频播放器-->
                            <div v-if="data.isEnd" style="height:550px;">
                                <video id="vod-player-container-id"
                                       preload="auto"
                                       playsinline
                                       webkit-playsinline
                                       height="550"
                                       style="width: 100%;"
                                />
                            </div>
                            <!--如果是直播中，使用直播播放器-->
                            <div v-if="!data.isEnd" id="player-container-id" muted
                                 style="width:100%; height:550px;"></div>
                            <div v-show="!playing"
                                 v-if="!data.isCanBack"
                                 style="position: relative; top: -550px; margin-bottom: -550px; z-index: 99;">
                                <el-image v-if="!data.isEnd && isStart"
                                          fit="cover"
                                          :src="require('../../assets/images/live1.png')"
                                          style="height: 550px; width: 100%;"
                                />
                                <el-image v-if="!data.isEnd && !isStart"
                                          fit="cover"
                                          :src="require('../../assets/images/live2.png')"
                                          style="height: 550px; width: 100%;"
                                />
                                <el-image v-if="data.isEnd && !data.isCanBack"
                                          fit="cover"
                                          :src="require('../../assets/images/live3.png')"
                                          style="height: 550px; width: 100%;"
                                />
                            </div>
                        </div>
                        <div class="title">
                            {{ data.name + (data.isCanBack ? '（回放）' : '') }}
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <!--课程目录及课程资料-->
                        <div class="course-info">
                            <!--标题-->
                            <div class="info-title">
                                <el-button class="button" :class="{active: currentModule === 0}"
                                           @click="currentModule = 0">互动讨论
                                </el-button>
                                <el-button class="button" :class="{active: currentModule === 1}"
                                           @click="currentModule = 1">课程资料
                                </el-button>
                            </div>
                            <!-- 互动讨论 -->
                            <div v-show="currentModule === 0" class="info-content">
                                <live-chat ref="liveChat" @sendChatTxt="sendChatTxt"/>
                            </div>
                            <!--课程资料-->
                            <div v-show="currentModule === 1" class="info-content">
                                <div v-for="(item, key) in fileList"
                                     :key="key"
                                     @click="downLoadFile(item.fileUrl)"
                                     class="module-info">
                                    <!--名称-->
                                    <div class="module">
                                        <span class="name">
                                            {{ item.titleName }}
                                        </span>
                                        <!--<i class="el-icon-arrow-down" style="font-weight: bold"></i>-->
                                        <i class="el-icon-download" style="font-weight: bold"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!--    教师信息-->
        <teacher-card :item="teacher"/>
        <!-- 直播websocket -->
        <live-websocket ref="websocket"
                        :id="$route.params.id"
                        @initComplete="initComplete()"
                        @receiveLiveStatus="receiveLiveStatus"
                        @receiveChatTxt="receiveChatTxt"
                        @receiveChatList="receiveChatList"
        />
    </div>
</template>

<script>
import TopBar from "@/layout/TopBar";
import TeacherCard from "../components/teacherCard";
import LiveWebsocket from "./liveWebsocket"
import liveChat from "./liveChat"

export default {
    name: "index",
    components: {
        LiveWebsocket,
        liveChat,
        TopBar,
        TeacherCard
    },
    data() {
        return {
            player: null,
            currentModule: 0,
            data: {},
            fileList: [],
            teacher: {},
            playing: true,
            poster: null,
            videoData: undefined
        }
    },
    computed: {
        isStart() {
            if (this.data.startTime) {
                let now = new Date()
                let start = new Date(this.data.startTime)
                return now > start
            }
            return false
        }
    },
    mounted() {
        if (this.$route.params.id) {
            this.$api.get('/live/index/getLiveDetail', {
                params: {
                    id: this.$route.params.id
                }
            }).then(res => {
                this.data = res.data
                this.poster = res.poster?.imgUrl
                //this.fileList = res.fileList
                this.teacher = res.teacher
                // 初始化websocket
                this.$refs.websocket.init()
                // 录制的video
                this.videoData = res.video
            }).catch(err => {
                console.log(err)
            })
        } else {
            this.$router.go(-1)
        }
    },
    beforeDestroy() {
        if (this.player) {
            // 销毁播放器
            if (this.data.isCanBack) {
                // 录播播放器销毁
                this.player.dispose()
            } else {
                // 直播播放器销毁
                this.player.destroy()
            }
        }
    },
    methods: {
        // websocket 初始化完成，判断直播间的状态
        initComplete() {
            let data = {
                type: 'LIVE_STATUS'
            }
            //发送信息，判断直播间状态
            this.$refs.websocket.sendMessage(JSON.stringify(data))

            let data1 = {
                type: 'CHAT_LIST'
            }
            // 获取以往聊天记录
            this.$refs.websocket.sendMessage(JSON.stringify(data1))
        },
        // 获取服务器信息
        receiveLiveStatus(val) {
            // 说明直播间正在播放中
            let _this = this
            if (!this.data.isEnd) {
                // 播放中
                if (val === 1) {
                    // 如果是从没有播放状态，进入播放状态，需要等一会
                    if (!this.playing) {
                        window.setTimeout(() => {
                            // 等待一会
                            _this.playing = true
                            // 没有播放，开始播放
                            _this.$nextTick(() => {
                                _this.playLive()
                            })
                        }, 3000)
                    } else {
                        // 等待一会
                        this.playing = true
                        // 没有播放，开始播放
                        this.$nextTick(() => {
                            _this.playLive()
                        })
                    }

                } else if (val === 0) {
                    this.playing = false
                    // 说明直播间暂停播放
                    if (this.player) {
                        this.playStop()
                    }
                } else if (val === 2) {
                    // 停止播放
                    this.playing = false
                    // 结束视频
                    this.data.isEnd = true
                    // 暂停播放
                    if (this.player) {
                        this.playStop()
                    }
                }
            } else {
                this.playing = false
                // 有回放，开始播放
                if (this.data.isCanBack && this.videoData) {
                    this.playVideo()
                }
            }
        },
        // 结束直播
        playStop() {
            if (this.player) {
                this.player.pause()
                this.player.destroy()
                this.player = null
            }
        },
        // 播放直播内容
        playLive() {
            let multiURLs = JSON.parse(this.data.playUrl)
            // 初始化
            if (!this.player) {
                // 需要初始化
                this.player = new window.TcPlayer('player-container-id', {
                    "m3u8": multiURLs[3].url.replace(this.$route.params.id + '.flv', this.$route.params.id + '.m3u8'),
                    // "flv": multiURLs[3].url, //增加了一个 flv 的播放地址，用于PC平台的播放，播放器自动识别PC环境
                    // "flash": false,
                    // "flv_hd": multiURLs[2].url, //增加了一个 flv 的播放地址
                    // "flv_sd": multiURLs[1].url, //增加了一个 flv 的播放地址
                    "poster": this.poster ? {"style": "stretch", "src": this.poster} : null,
                    "live": true,
                    "autoplay": true,      //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
                    "width": '100%',//视频的显示宽度，请尽量使用视频分辨率宽度
                    "height": '550', //视频的显示高度，请尽量使用视频分辨率高度
                    "wording": {
                        2: "网络有延迟，请刷新页面重试",
                        2032: "请求视频失败，请检查网络",
                        2048: "请求m3u8文件失败，可能是网络错误或者跨域问题"
                    },
                    "listener": this.listener
                });
                console.log(multiURLs[3].url)
            }
            this.player.load()
        },
        // 监听内容
        listener(info) {
            if (info.type === 'error') {
                console.log(info)
                // 说明网络问题
                if (info.detail !== null && info.detail.code === 2) {
                    // 重新加载
                    this.player.load()
                }
            }
        },
        // 播放直播内容
        playVideo() {
            console.log(this.videoData)
            // 需要初始化
            this.player = window.TCPlayer('vod-player-container-id', {
                fileID: this.videoData.videoId,
                appID: '1307961979',
                autoplay: true,
                plugins: {
                    ProgressMarker: true
                }
            }); // player-container-id 为播放器容器 ID，必须与 html 中一致
        },
        // 获取广播-文字
        receiveChatTxt(msg) {
            let data = JSON.parse(msg)
            // 发送给子组件
            this.$refs.liveChat.receiveChatTxt(data.msg)
        },
        // 获取历史聊天记录
        receiveChatList(msg) {
            // 发送给子组件
            this.$refs.liveChat.receiveChatList(msg)
        },
        // 发送广播文字
        sendChatTxt(data) {
            this.$refs.websocket.sendMessage(JSON.stringify(data))
        }
    }
}
</script>

<style scoped lang="scss">
.body {
    width: 100%;
    background: #222736;
    padding-top: 130px;

    .content {
        width: 1200px;
        margin: 0 auto;
        height: 650px;

        .title {
            margin-top: 15px;
            margin-bottom: 5px;
            font-size: 20px;
            font-weight: 500;
            color: #fff;
        }

        .course-info {
            background: #1d202b;
            height: 630px;

            .info-title {
                padding: 15px 0;
                text-align: center;
                background-color: #2b2f38;

                .button {
                    padding: 5px 8px;
                    margin: 0 10px;
                    width: 82px;
                    border-radius: 2px;
                    border: 1px solid transparent;
                    font-size: 16px;
                    font-weight: 500;
                    background: transparent;
                    color: #fff;
                }

                .active {
                    background: $sr-main-color;
                }
            }

            .info-content {

                .module-info {
                    .module {
                        display: flex;
                        -webkit-box-align: center;
                        -ms-flex-align: center;
                        align-items: center;
                        height: 44px;
                        padding: 10px;
                        margin-bottom: 10px;
                        border-radius: 5px;
                        line-height: 24px;
                        background: #484d53;
                        color: #fff;
                        cursor: pointer;

                        .name {
                            @include text-overflow(1);
                            font-size: 14px;
                            flex: 1;
                            white-space: nowrap;
                        }
                    }

                    .infos {
                        padding: 10px 20px;
                        color: hsla(0, 0%, 100%, .7);

                        .infos-card {

                            cursor: pointer;
                            padding: 12px 0;

                            &:not(:first-child) {
                                border-top: 1px solid hsla(0, 0%, 100%, .1);
                            }

                            .name {
                                @include text-overflow(1);
                                font-size: 13px;
                                line-height: 18px;
                            }

                            .info {
                                margin-top: 10px;
                                font-size: 13px;
                                line-height: 17px;
                                color: #a1a4b3;

                                .is-playing-tips {
                                    padding-left: 20px;
                                    color: $sr-main-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .is-playing-color {
        color: $sr-main-color;
    }
}
</style>