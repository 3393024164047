<template>
    <div>
        <div>
            <!-- 聊天框区域 -->
            <div class="list">
                <el-scrollbar ref="chatList" style="height: 100%">
                    <div :id="'item'+index" v-for="(item, index) in chatList" :key="index">
                        <div :class="['item', item.type]">
                            <div>
                                <div class="info">
                                    <el-avatar class="avatar" :src="item.avatar" :size="28"/>
                                    <span v-if="item.role === 'anchor'" class="anchor">讲师</span>
                                    <span class="name" style="max-width: 100px;">{{ item.name }}</span>
                                </div>
                                <div class="chat-content" :class="[item.type]">
                                    <div class="sentence content">
                                        {{ item.content }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <!-- 输入框区域 -->
            <div class="actionBottom">
                <div class="tool">
                    <el-popover
                        placement="top-start"
                        width="300"
                        trigger="click">
                        <el-scrollbar style="height: 200px">
                            <emoji-icon @clickIcon="selEmoji"/>
                        </el-scrollbar>
                        <svg-icon slot="reference" name="emoji" style="font-size: 25px"/>
                    </el-popover>
                </div>
                <el-input
                    v-model="inputText"
                    resize="none"
                    class="input"
                    type="textarea"
                    :rows="2"
                    placeholder="说点什么吧~"
                    @keyup.enter.native="confirmInput"
                />
                <div class="send">
                    <el-button type="warning" size="mini" @click="confirmInput">发送</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "liveChat",
    data() {
        return {
            chatList: [], // 聊天列表
            inputText: '' // 输入框内容
        }
    },
    methods: {
        // 确定输入
        confirmInput() {
            if (!this.inputText) {
                this.$message.warning('请输入内容')
            }

            // 拼装信息
            let data = {
                type: 'CHAT_TXT',
                msg: {
                    id: this.$store.state.userInfo.id,
                    avatar: this.$store.state.userInfo.avatarUrl,
                    name: this.$store.state.userInfo.nickName,
                    content: this.inputText.replaceAll('\n', ''),
                    type: 'right'
                }
            }
            //发送信息，判断直播间状态
            this.$emit('sendChatTxt', data)

            this.inputText = ''
            this.scrollBottom()
        },
        // 接受聊天间信息
        receiveChatTxt(data) {
            console.log(data)
            // 判断是自己发的还是其他人
            data.type = this.$store.state.userInfo.id === data.id ? 'right' : 'left'
            // 发送信息
            this.chatList.push(data)
            // 如果条数大于150，删除前50条
            if (this.chatList.length > 150) {
                this.chatList.splice(0, 50)
            }
            // 回滚最后
            this.scrollBottom()
        },
        // 接受以往聊天记录
        receiveChatList(data) {
            let _this = this
            let temp = []
            // 循环添加历史记录
            data.forEach(item => {
                let msg = JSON.parse(item.msg)
                msg = msg.msg
                msg.type = _this.$store.state.userInfo.id === msg.id ? 'right' : 'left'
                temp.push(msg)
            })
            // 发送信息
            this.chatList = temp
            // 新增欢迎语
            this.chatList.push({
                avatar: 'https://lawprof-1307961979.cos.ap-beijing.myqcloud.com/avator.png',
                content: '欢迎来到法教授直播间！',
                name: '法教授官方',
                type: 'left'
            })
            // 回滚最后
            this.scrollBottom()
        },
        // 跳转到最后
        scrollBottom() {
            const container = this.$refs['chatList'].$refs['wrap']
            this.$nextTick(() => {
                container.scrollTop = container.scrollHeight
            })
        },
        // 选择表情
        selEmoji(item) {
            this.inputText += item.emoji;
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-textarea__inner {
    height: 100px;
    background-color: transparent;
    color: #FFFFFF;
    border: none;
}

::v-deep .el-scrollbar__thumb {
    display: none;
}

.list {
    height: 400px;
    padding: 0 10px;
    overflow: hidden;

    .item {
        display: flex;

        .info {
            display: flex;
            align-items: center;

            .anchor {
                padding: 3px 5px;
                background: linear-gradient(50deg, #89aaef, #0f5cfb);
                color: #FFFFFF;
                font-size: 11px;
                border-radius: 3px;
            }

            .name {
                @include text-overflow(1);
                font-size: 15px;
                color: hsla(0, 0%, 100%, .6);
                margin: 0 10px;
            }
        }
    }

    .chat-content {
        .sentence {
            padding: 8px 15px;
            margin-top: 13px;
            margin-bottom: 20px;
            font-size: 14px;
            display: inline-block;
        }
    }

    .left {
        flex-direction: row;

        .anchor {
            margin-left: 5px;
        }

        .content {
            color: hsla(0, 0%, 100%, .8);
            background-color: #464a52;
            border-radius: 1px 8px 8px 8px;
            margin-left: 5px;
        }
    }

    .right {
        flex-direction: row-reverse;

        .info {
            flex-direction: row-reverse;

            .anchor {
                margin-right: 5px;
            }
        }

        .chat-content {
            display: flex;
            flex-direction: row-reverse;
            margin-right: 20px;
        }

        .content {
            color: #FFFFFF;
            background-color: $sr-main-color;
            border-radius: 8px 1px 8px 8px;
            text-align: left;
            margin-right: 5px;
        }
    }
}

.actionBottom {
    height: 230px;

    .tool {
        padding: 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 35px;
        background-color: #2b2f38;
    }

    .send {
        height: 60px;
        text-align: right;
        padding-right: 15px;
    }
}
</style>