<template>
    <div></div>
</template>

<script>
export default {
    name: "liveWebsocket",
    props: {
        id: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            timeout: 1 * 10 * 1000, // 1 * 60秒一次心跳
            timeoutObj: null, // 心跳心跳倒计时
            websocket: null
        }
    },
    beforeDestroy() {
        // 关闭WebSocket
        if (this.websocket) {
            this.websocket.close()
        }
    },
    methods: {
        // 初始化Websocket
        init() {
            if ('WebSocket' in window) {
                this.websocket = new WebSocket(process.env.VUE_APP_WS_ROOT + '/live/' + this.id)
                // 连接成功
                this.websocket.onopen = this.setWebsocketOpen
                // 收到消息的回调
                this.websocket.onmessage = this.onSocketMessage
                // 异常处理回调
                this.websocket.onerror = this.onSocketError
                // 关闭回调
                this.websocket.onclose = this.onSocketClose
            } else {
                alert('当前浏览器不支持直播！请切换其他浏览器')
            }
        },
        // websocket 连接成功
        setWebsocketOpen() {
            console.log('开启心跳连接')
            // 上报初始化状态完成
            this.$emit('initComplete')
            // 开启心跳
            this.startHeart()
        },
        // 开启心跳
        startHeart() {
            const _this = this;
            this.timeoutObj && clearTimeout(this.timeoutObj)
            this.timeoutObj = setTimeout(() => {
                // 这里发送一个心跳，后端收到后，返回一个心跳消息
                let data = {
                    type: 'HEART'
                }
                _this.websocket.send(JSON.stringify(data))
                console.log('---心跳连接一次---')
            }, this.timeout)
        },
        // websocket 接收消息
        onSocketMessage(res) {
            let data = JSON.parse(res.data)
            // 心跳机制
            if (data.type === 'HEART') {
                // 心跳发送
                this.startHeart()
                return
            }
            // 获取直播间状态
            if (data.type === 'LIVE_STATUS') {
                this.$emit('receiveLiveStatus', data.msg)
                return
            }
            // 获取文字广播内容
            if (data.type === 'CHAT_TXT') {
                this.$emit('receiveChatTxt', data.msg)
                return
            }
            // 获取聊天室历史记录
            if (data.type === 'CHAT_LIST') {
                this.$emit('receiveChatList', data.msg)
                return
            }
        },
        // websocket 异常处理
        onSocketError() {
            console.log('websocket连接失败，两秒后重新开始连接')
            // 2秒后重试，重新开启连接
            let _this = this
            setTimeout(() => {
                _this.init()
            }, 2000)
        },
        // websocket 关闭回调
        onSocketClose() {
            console.log('WebSocket 已关闭！');
        },
        // 发送信息
        sendMessage(val) {
            this.websocket.send(val)
        }
    }

}
</script>